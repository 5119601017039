const translations = [
  {
    language: "pl-PL",
    greeting: "Dzień dobry,",
    afterGreetingSumUp: "w nawiązaniu do naszej rozmowy, przesyłam ofertę ",
    Price: "Cena: ",
    OR: "lub",
    lessonPlan: "Plan zajęć: ",
    noAvailableDates: "Brak dostępnych terminów",
    availableDates: "Dostępne terminy: ",
    timetableHour: "Godzina",
    timetableDay: "Dzień tygodnia",
    timetableStartDate: "Data startu",
    timetableAvailableSpots: "Wolne miejsca",
    regards: "Pozdrawiamy, ",
    customerServiceTeam: "Zespół ds. obsługi klienta",
    duration: "Częstotliwość spotkań:  ",
    multipleTimesAWeek: "Kilka razy w tygodniu"
  },
  {
    language: "es-ES",
    greeting: "Buenos días,",
    afterGreetingSumUp: "después de nuestra conversación, estoy enviando una oferta ",
    Price: "Precio: ",
    OR: "o",
    lessonPlan: "Horario: ",
    noAvailableDates: "No hay fechas disponibles",
    availableDates: "Fechas disponibles: ",
    timetableHour: "Hora",
    timetableDay: "Día de la semana",
    timetableStartDate: "Fecha de inicio",
    timetableAvailableSpots: "Vacantes",
    regards: "Saludos, ",
    customerServiceTeam: "Equipo de atención al cliente",
    duration: "Frecuencia de las reuniones:  ",
    multipleTimesAWeek: "Varias veces por semana"
  },
  {
    language: "it-IT",
    greeting: "Buongiorno,",
    afterGreetingSumUp: "in seguito alla nostra conversazione, vi invio la nostra offerta ",
    Price: "Prezzo: ",
    OR: "o",
    lessonPlan: "Programma delle lezioni: ",
    noAvailableDates: "Nessuna data disponibile",
    availableDates: "Date disponibili: ",
    timetableHour: "Orario",
    timetableDay: "Giorno della settimana",
    timetableStartDate: "Data di inizio",
    timetableAvailableSpots: "Posti disponibili",
    regards: "Saluti, lezioni",
    customerServiceTeam: "Servizio clienti Coding Giants",
    duration: "Frequenza delle :  ",
    multipleTimesAWeek: "Più volte alla settimana"
  },
];

export default translations;
